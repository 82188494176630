<template>
    <div class="font-semi-bold" :key="formKey">
        <s-form>

            <div class="row d-sm-none d-md-none">

                <div class="col-2 col-lg-3 col-md order-lg-1 order-3 px-1">
                    <div class="fl-x-res fl-a-c">
                        <span class="mr-2 font-inter-medium">Filters</span>

                        <validated-input class="m-0  c-input-1 truncate-selected-text" type="number" @input="setFilter"
                                         placeholder="Price from" v-model="model.price_from"></validated-input>
                    </div>
                </div>

                <div class="col-2 col-lg-3 order-lg-2 order-4 px-1">
                    <validated-input class="c-input-1 m-0 truncate-selected-text" type="number" @input="setFilter"
                                     placeholder="Price To" v-model="model.price_to"></validated-input>
                </div>

                <div class="col-2 col-lg-3 order-lg-3 order-5 px-1">

                    <date-picker type="date" class="border-gray w-100 c-datepicker truncate-selected-text" v-model="model.start_date"
                                 placeholder="Starting Date" @input="setFilter" format="DD-MM-YYYY" ></date-picker>

                </div>

                <div class="col-3 col-lg-3 order-lg-4 order-6 m-0 px-1">
                    <date-picker type="date" class="border-gray w-100 c-datepicker truncate-selected-text" v-model="model.end_date"
                                 placeholder="Ending Date" @input="setFilter" format="DD-MM-YYYY" ></date-picker>

                </div>

                <!--                <div class="col-3 col-lg-3 order-lg-5 order-7 px-1 ">-->
                <!--                    <validated-ajax-vue-select class=" c-input-1 c-in m-0 fs&#45;&#45;3 truncate-selected-text" :url="bankUrl" @input="setFilter"-->
                <!--                                               placeholder="Bank/FI" v-model="model.bank"></validated-ajax-vue-select>-->
                <!--                </div>-->

            </div>

            <div class="row d-sm-none d-md-none ">

                <div class="col-3 col-lg-5 order-lg-5 order-7 px-1 pt-3">
                    <validated-ajax-vue-select class=" c-input-1 c-in m-0 fs--3 truncate-selected-text" :url="bankUrl" @input="setFilter"
                                               placeholder="Bank/FI" v-model="model.bank"></validated-ajax-vue-select>
                </div>

                <div class="col-3 col-lg-3 order-lg-5 order-7 px-1 mt-3">
                    <validated-ajax-vue-select class=" c-input-1 m-0 truncate-selected-text" :url="stateUrl" @blur="loadDistrict"
                                               @input="setFilter('state')"
                                               placeholder="State" v-model="model.state"></validated-ajax-vue-select>
                </div>

                <div class="col-3 col-lg-4 order-lg-5 order-7 px-1 mt-3">
                    <validated-vue-select class=" c-input-1 m-0 truncate-selected-text" :options="districtOptions" @input="setFilter"
                                          placeholder="District" v-model="model.district"></validated-vue-select>
                </div>
            </div>
            <div class="row d-sm-none d-md-none ">
                <div class="col-3 col-lg-4 order-lg-6 order-8 px-1 mt-3">
                    <validated-ajax-vue-select class=" c-input-1 m-0 truncate-selected-text" :url="propertyTypeUrl" @input="setFilter"
                                               placeholder="Category"
                                               v-model="model.base_type"></validated-ajax-vue-select>
                </div>

                <div class="col-3 col-lg-4 order-lg-6 order-8 px-1 mt-3">
                    <validated-ajax-vue-select class=" c-input-1 m-0 truncate-selected-text" :url="propertySubTypeUrl" @input="setFilter"
                                               placeholder="Sub Category"
                                               v-model="model.property_type"></validated-ajax-vue-select>
                </div>

                <div class="col-3 col-lg-4 order-lg-6 order-8 px-1 mt-3">
                    <validated-vue-select class=" c-input-1 m-0 truncate-selected-text" :options="booleanOptions"
                                          @input="setFilter"
                                          placeholder="Platform"
                                          v-model="model.is_third_party"></validated-vue-select>
                </div>
                <div class="col-12 col-lg-12 order-lg-7 order-1 px-1  mt-3">
                    <validated-input class=" c-input-1 m-0 truncate-selected-text" placeholder="Search" v-model="model.extra_search"
                                     @input="setFilter">
                        <template #prepend>
                            <i class="fa fa-search"></i>
                        </template>
                    </validated-input>
                </div>

                <div class="col-3 col-lg-3 order-lg-8 order-2 d-lg-none d-md-none px-1">
                    <btn icon="fa fa-filter"></btn>
                </div>

            </div>

            <!--mobile design-->
            <div class="d-lg-none">

                <div class="">
                    <div class="row">
                        <div class="col-8">
                            <validated-input class="" placeholder="Search" v-model="model.extra_search"
                                             @input="setFilter">
                                <template #prepend>
                                    <i class="fa fa-search"></i>
                                </template>
                            </validated-input>
                        </div>
                        <div class="col-4">
                            <btn icon="fa fa-filter" @click="showFilterMobile = !showFilterMobile"></btn>
                        </div>
                    </div>
                </div>

                <!--                Mobile and Tabs Design-->
                <div class="row d-lg-none" v-if="showFilterMobile">

                    <div class="col-12 col-lg-2 col-md-6 order-lg-1 order-3 mb-3 mb-lg-0">
                        <div class=" fl-a-c">
                            <div class="text-left font-inter-semi-bold fs-3 mb-2">Filters</div>

                            <validated-input class="m-0  c-input-1" type="number" @blur="setFilter"
                                             placeholder="Price from" v-model="model.price_from"></validated-input>
                        </div>
                    </div>

                    <div class="col order-lg-2 col-md-6 order-4">
                        <div class="">
                            <validated-input class=" c-input-1" type="number" @blur="setFilter"
                                             placeholder="Price To" v-model="model.price_to"></validated-input>
                        </div>
                    </div>

                    <div class="col order-lg-3 col-md-6 order-5 mb-3 mb-lg-0 px-1">
                        <div class="px-2">
                            <date-picker type="date" class="border-gray w-100 c-datepicker"
                                         v-model="model.start_date" @blur="setFilter"
                                         placeholder="Starting Date"></date-picker>
                        </div>
                    </div>

                    <div class="col order-lg-4 col-md-6 order-6 mb-3 mb-lg-0 px-1">
                        <div class="px-2">
                            <date-picker type="date" class="border-gray c-datepicker w-100"
                                         v-model="model.end_date" @blur="setFilter"
                                         placeholder="Ending Date"></date-picker>
                        </div>
                    </div>

                    <div class="col order-lg-5 col-md-6 order-7 px-1 mt-3">
                        <div class="px-2">
                            <validated-ajax-vue-select class="c-input-1" :url="bankUrl" @input="setFilter"
                                                       placeholder="Bank/FI"
                                                       v-model="model.bank"></validated-ajax-vue-select>
                        </div>
                    </div>

                    <div class="col order-lg-5 col-md-6 order-7 px-1">
                        <div class="px-2">
                            <validated-ajax-vue-select class="c-input-1 c-in" :url="stateUrl"
                                                       @input="setFilter('state')"
                                                       placeholder="State"
                                                       v-model="model.state"></validated-ajax-vue-select>
                        </div>
                    </div>

                    <div class="col order-lg-5 col-md-6 order-7 px-1">
                        <div class="px-2">
                            <validated-vue-select class="c-input-1" :options="districtOptions" @input="setFilter"
                                                  placeholder="District"
                                                  v-model="model.district"></validated-vue-select>
                        </div>
                    </div>

                    <div class="col order-lg-6 col-md-6 order-8 px-1">
                        <div class="px-2">
                            <validated-ajax-vue-select class=" c-input-1" :url="propertyTypeUrl" @input="setFilter"
                                                       placeholder="Category"
                                                       v-model="model.base_type"></validated-ajax-vue-select>
                        </div>

                        <div class="px-2">
                            <validated-ajax-vue-select class=" c-input-1" :url="propertySubTypeUrl" @input="setFilter"
                                                       placeholder="Sub Category"
                                                       v-model="model.property_type"></validated-ajax-vue-select>
                        </div>
                    </div>

                    <div class="col order-lg-7 col-md-6 order-1 px-1 d-sm-none">
                        <div class="px-2">
                            <validated-input class=" c-input-1 m-0" placeholder="Search" v-model="model.extra_search"
                                             @input="setFilter">
                                <template #prepend>
                                    <i class="fa fa-search"></i>
                                </template>
                            </validated-input>
                        </div>
                    </div>

                </div>
            </div>
        </s-form>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name: 'AuctionFilters',

    components: { DatePicker },

    computed: {
        name () {
            return this.$route.params.name;
        }
    },

    watch: {
        name () {
            this.model.base_type = this.$route.params.name;
            this.propertySubTypeUrl = urls.website.SubPropertyOptions + '?type=Text&property_type=' + this.$route.params.name;
            this.formKey++;
        },
        'model.base_type' () {
            this.propertySubTypeUrl = urls.website.SubPropertyOptions + '?type=Text&property_type=' + this.model.base_type;
            this.formKey++;
        }
    },

    data () {
        return {
            booleanOptions: [
                {
                    label: 'Others',
                    value: true
                },
                {
                    label: 'Bank e Auctions India.com',
                    value: false
                }
            ],
            formKey: 0,

            showFilterMobile: false,

            model: {
                base_type: this.$route.params.name,
                start_date: '',
                end_date: '',
                price_from: '',
                price_to: '',
                extra_search: '',
                property_type: '',
                state: '',
                district: '',
                bank: ''
            },

            districtOptions: [],

            stateUrl: urls.website.stateOptions + '?type=Text',
            propertyTypeUrl: urls.website.propertyOptions + '?type=Text',
            propertySubTypeUrl: urls.website.SubPropertyOptions + '?type=Text&property_type=' + this.$route.params.name,
            bankUrl: urls.website.bankOptions
        };
    },

    beforeMount () {
        this.loadDistrict();
    },

    methods: {
        setFilter (key = 'null') {
            if (key === 'state') {
                this.model.district = '';
            }
            this.$emit('filterUpdate', this.model);
        },

        async loadDistrict () {
            const response = await axios.get(urls.website.districtOptions + '?type=Text&state=' + this.model.state);
            this.districtOptions = response.data.data;
        }
    }
};
</script>

<style scoped>

</style>
